import request from "@/utils/request";

//获取动态列表
export function get_dynamic(data) {
  return request({
    url: "/index/dynamic",
    method: "get",
    params: data,
  });
}

export function get_position(data) {
  return request({
    url: "/index/position",
    method: "get",
    params: data,
  });
}

//申请职位
export function get_apply(data) {
  return request({
    url: "/index/wanted",
    method: "post",
    data,
  });
}

//提交信息
export function get_visit(data) {
  return request({
    url: "/index/visit",
    method: "post",
    data,
  });
}
