import axios from "axios";

import global_url from "@/utils/global";
import router from "../router";
import { Message } from "element-ui";

const service = axios.create({
  baseURL: global_url.httpUrl,
  timeout: 60 * 1000,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

//添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // config.data = Qs.stringify(config.data);
    let token = sessionStorage.getItem("token");
    if (!token) {
      console.log();
    } else {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

//添加响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.status !== 200) {
      if (response.status == 300) {
        return res;
      } else if (response.status == 301) {
        sessionStorage.clear();
        router.push("/login");
        return res;
      } else if (response.status == 500) {
        // this.$message.error("服务器错误");
        return res;
      } else if (response.status == 400) {
        return res;
      } else {
        return res;
      }
    } else {
      if (response.data.code == 20002) {
        Message({
          message: "账户未登录",
          type: "error",
        });
        sessionStorage.clear();
        router.push("/login");
        return res;
      }
      return res;
    }
  },
  (error) => {
    if (error.response.status == 300) {
      return error.response;
    } else if (error.response.status == 301) {
      Message({
        message: "账户未登录",
        type: "error",
      });
      sessionStorage.clear();
      router.push("/login");
      return error.response;
    } else if (error.response.status == 500) {
      Message({
        message: "服务器错误",
        type: "error",
      });
      return error.response;
    } else if (error.response.status == 400) {
      Message({
        message: "请求错误",
        type: "error",
      });
      return error.response;
    } else {
      return error.response;
    }
  }
);

export default service;
