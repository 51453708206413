var httpUrl, httpUrlFile;

// httpUrl = "https://api.gateway.ysyup.com/sxx"; //正式
// httpUrl = "http://133.123.1.191:85/sky-web";//本地
httpUrl = "http://47.108.52.214/skyadm"; //测试




httpUrlFile = httpUrl + "/PERSONAL/DOWNLOAD/";

export default {
  httpUrl,
  httpUrlFile,
};
